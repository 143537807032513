import "../assets/css/header.css";
import "../assets/css/responsive.css";
import { Form, Navbar, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { guide, logo } from "./Images";
import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import Onboard from "bnc-onboard";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
const INFURA_ID = "a3573077-8a08-483c-b4a0-6a4544c59985";
function Header() {
  const { t, i18n } = useTranslation();
  const [lang, setLanguage] = useState("en");

  const handlechange = (e) => {
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
    console.log(e.target.value);
  };
  const [connect, setConnect] = useState(false);
  const loadProvider = async () => {
    try {
      const web3Modal = new web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      return provider.getSigner();
    } catch (e) {
      console.log("loadProvider default: ", e);
    }
  };

  const {
    connector,
    library,
    account,
    chainId,
    activate,
    deactivate,
    active,
    errorWeb3Modal,
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React();
  let provider;
  const onboard = Onboard({
    dappId: INFURA_ID, //e5dce034-797e-4871-8a93-ef69730dca19
    networkId: 3,
    darkMode: true,
    subscriptions: {
      wallet: async (wallet) => {
        if (wallet.provider) {
          setConnect(true);
        } else {
          setConnect(false);
        }
      },
    },
    walletSelect: {
      wallets: [
        {
          walletName: "metamask",
          preferred: true,
        },
        {
          walletName: "walletConnect",
          preferred: true,
          infuraKey: INFURA_ID,
          bridge: "https://bridge.walletconnect.org",
        },
      ],
    },
  });
  const connectWalletPressed = async () => {
    let wallet = await onboard.walletSelect();
    await onboard.walletCheck();
  };
  useEffect(async () => {
    loadProvider();
  }, [connect]);
  return (
    <header>
      <div className="custom-header pc-header">
        <div className="logo-section">
          <img src={logo} className="logo" />
        </div>
        <div className="flex-btn-group w-50">
          <button className="wallet-btn" onClick={connectWalletPressed}>
            {t("connectwallet")}
          </button>
          <button className="guide-btn">
            <img src={guide} />
            <span>{t("docs")}</span>
          </button>
          <Form.Select
            className="mx-3 w-25"
            aria-label="Default select example"
            onChange={handlechange}
            value={lang}
          >
            <option value="en">English</option>
            <option value="chi">Chineese</option>
          </Form.Select>
        </div>
      </div>

      <Navbar className="mobile-header d-none" expand="lg">
        <Navbar.Brand>
          <div className="logo-section">
            <img src={logo} className="logo" alt="Logo" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto align-items-start">
            {/* <li>
                <Link to={"/community"}>Community Art</Link>
              </li>
              <li>
                <Link to={"/voting"}>Voting</Link>
              </li> */}
            <li>
              <button className="wallet-btn" onClick={connectWalletPressed}>
                Connect to Wallet
              </button>
            </li>
            <li>
              <button className="guide-btn">
                <img src={guide} />
                <span>Docs</span>
              </button>
            </li>
            <li>
              <Form.Select
                className="mx-3 w-25"
                aria-label="Default select example"
                onChange={handlechange}
                value={lang}
              >
                <option value="en">English</option>
                <option value="chi">Chineese</option>
              </Form.Select>
            </li>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header;
