import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { eth, matic, Bnb, Bitcoin } from "../Images";

function Coins(props) {
  let coins = [
    { name: "BITCOIN", image: Bitcoin },
    { name: "BNB", image: Bnb },
    { name: "ETH", image: eth },
    { name: "MATIC", image: matic },
  ];
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>SELECT ASSET</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control search-box"
            placeholder="Search token"
          />
          <ul className="coins-head">
            <li>WAN</li>
            <li>BTC</li>
            <li>ETH</li>
            <li>XRP</li>
            <li>USDT</li>
          </ul>
          <div className="coins-list-main">
            <ul className="coins">
              {coins.map((coin, ind) => {
                return (
                  <div key={ind}>
                    <li onClick={props.handleClose}>
                      <img src={coin.image} />
                      <span>{coin.name}</span>
                    </li>
                  </div>
                );
              })}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Coins;
