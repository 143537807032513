import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Tabs() {
  const { t } = useTranslation();
  return (
    <>
      <div className="btn-tabs">
        <NavLink to={"/"}>
          <i class="fa-solid fa-house-user"></i>
          <span>{t("home")}</span>
        </NavLink>
        <NavLink to={"/history"}>
          <i class="fa-solid fa-clock-rotate-left"></i>
          <span>{t("history")}</span>
        </NavLink>
      </div>
    </>
  );
}

export default Tabs;
